/* stylelint-disable scss/dollar-variable-empty-line-before */
/* stylelint-disable-next-line value-keyword-case */
/** Bulma: Variables **/
/* stylelint-disable keyframes-name-pattern */
/** Animations **/
@keyframes pulseWarning {
  0% {
    border-color: hsl(44deg, 100%, 77%);
  }
  100% {
    border-color: #946c00;
  }
}
@keyframes pulseWarningBody {
  0% {
    background-color: hsl(44deg, 100%, 77%);
  }
  100% {
    background-color: #946c00;
  }
}
/** Main **/
.swal-modal {
  background-color: hsl(0deg, 0%, 21%);
}
.swal-modal.is-expanded {
  width: auto;
  max-width: 90%;
}
.swal-modal .field {
  text-align: initial;
}

.swal-title,
.swal-text {
  color: hsl(0deg, 0%, 96%);
}

.swal-text {
  text-align: center;
}

.swal-content .label {
  color: hsl(0deg, 0%, 96%);
}
.swal-content .checkbox,
.swal-content .radio {
  color: hsl(0deg, 0%, 96%);
}
.swal-content .checkbox:hover,
.swal-content .radio:hover {
  color: hsl(0deg, 0%, 93%);
}

.swal-button--confirm:not(.swal-button--danger) {
  background-color: hsl(217deg, 89%, 61%);
}
.swal-button--confirm:not(.swal-button--danger):hover {
  background-color: #0c53c6;
}
.swal-button--confirm:not(.swal-button--danger):focus {
  box-shadow: 0 0 0 1px hsl(0deg, 0%, 7%), 0 0 0 3px #0c53c6;
}

.swal-button--danger {
  background-color: hsl(348deg, 100%, 61%);
}
.swal-button--danger:hover {
  background-color: #db002c;
}
.swal-button--danger:focus {
  box-shadow: 0 0 0 1px hsl(0deg, 0%, 7%), 0 0 0 3px #db002c;
}

.swal-button--cancel {
  background-color: hsl(171deg, 100%, 41%);
  color: hsl(0deg, 0%, 100%);
}
.swal-button--cancel:hover {
  background-color: #00947e;
}
.swal-button--cancel:focus {
  box-shadow: 0 0 0 1px hsl(0deg, 0%, 7%), 0 0 0 3px #00947e;
}

.swal-button--loading {
  color: transparent;
}

.swal-icon--info {
  border-color: hsl(217deg, 89%, 61%);
}
.swal-icon--info::after, .swal-icon--info::before {
  background-color: hsl(217deg, 89%, 61%);
}

.swal-icon--error {
  border-color: hsl(348deg, 100%, 61%);
}

.swal-icon--error__line {
  background-color: hsl(348deg, 100%, 61%);
}

.swal-icon--warning {
  border-color: hsl(44deg, 100%, 77%);
  animation: pulseWarning 0.5s infinite alternate;
}

.swal-icon--warning__body,
.swal-icon--warning__dot {
  background-color: hsl(44deg, 100%, 77%);
  animation: pulseWarningBody 0.5s infinite alternate;
}

.swal-icon--success {
  border-color: hsl(141deg, 42%, 53%);
}
.swal-icon--success::after, .swal-icon--success::before {
  background-color: hsl(0deg, 0%, 21%);
}

.swal-icon--success__line {
  background-color: hsl(141deg, 42%, 53%);
}

.swal-icon--success__hide-corners {
  background-color: hsl(0deg, 0%, 21%);
}

.swal-display-thumb-container {
  min-width: 200px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swal-display-description-preview {
  background-color: hsl(0deg, 0%, 0%);
  border-radius: 4px;
  padding: calc(0.5em - 1px) calc(0.75em - 1px) calc(0.5em - 1px) calc(0.75em - 1px);
}
.swal-display-description-preview.content {
  margin-bottom: 0;
}